import toast from "react-hot-toast";

// Define the type for the successToast function
export const successToast = (message) => {
  return toast.success(message, {
    style: {
      background: "green",
      color: "#fff",
    },
  });
};
